import { type ListingCardView } from '@/features/listing/types/listing-types';

interface AppConfig {
  isMobileAuthEnabled: boolean;
  defaultListingCardView: ListingCardView;
  loginLink: string;
}

const APP_CONFIG: AppConfig = {
  defaultListingCardView: 'list',
  isMobileAuthEnabled: false,
  loginLink: '/phone-auth',
};

export default APP_CONFIG;

'use client';

import cx from 'classix';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import {
  useIsClient,
  useScreen,
} from 'usehooks-ts';

import APP_CONFIG from '@/configs/app-config';
import useAppFlyerLink from '@/features/roomster-app/hooks/use-app-flyer-link';
import BREAKPOINTS from '@/shared/constants/breackpoints';
import { ROUTER_LINKS } from '@/shared/constants/links-constants';

import styles from './create-listing.module.scss';

interface CreateListingProps {
  isShow: boolean
}

export default function CreateListing(props: CreateListingProps) {
  const { isShow } = props;
  const t = useTranslations('home.listing.create');
  const screen = useScreen();
  const isClient = useIsClient();
  const listPlaceAppFlyerLink = useAppFlyerLink('mobile', 'mobileListPlace');
  const findPlaceAppFlyerLink = useAppFlyerLink('mobile', 'mobileFindPlace');

  const isMobile = isClient && screen.width < BREAKPOINTS.MOBILE_SCREEN_WIDTH;
  const listPlaceLink = isMobile && !APP_CONFIG.isMobileAuthEnabled ? listPlaceAppFlyerLink : ROUTER_LINKS.phoneAuth;
  const findPlaceLink = isMobile && !APP_CONFIG.isMobileAuthEnabled ? findPlaceAppFlyerLink : ROUTER_LINKS.phoneAuth;

  return (
    <section className={cx(styles.createListingSection, !isShow && styles.createListingSection__hidden)}>
      <div className={styles.createListingSection_content}>
        <div className={cx(styles.createBanner, styles.listPlace)}>
          <Image
            className={styles.listPlace}
            src="/images/create-listing/sofa.webp"
            alt="list a place"
            fill
            priority
            loading="eager"
          />
          <Link className={styles.createListingSection_button} href={listPlaceLink}>
            {t('list-a-place')}
          </Link>
        </div>

        <div className={cx(styles.createBanner, styles.findPlace)}>
          <Image
            className={styles.listPlace}
            src="/images/create-listing/girl.webp"
            alt="list a place"
            fill
            priority
            loading="eager"
          />
          <Link className={styles.createListingSection_button} href={findPlaceLink}>
            {t('find-a-place')}
          </Link>
        </div>
      </div>
    </section>
  );
}

import { type ReactNode } from 'react';

import styles from './badge.module.scss';

interface BadgeProps {
  children: ReactNode;
}

export default function Badge(props: BadgeProps) {
  const { children } = props;

  return (
    <div className={styles.badge}>
      {children}
    </div>
  );
}

'use client';

import { useAtomValue } from 'jotai/index';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useIsMounted } from 'usehooks-ts';

import { loggedUserAtom } from '@/features/user/atoms/logged-user-atom';

let oldPath = '';

const watchPath = (path: string) => {
  if (oldPath === path) return;

  oldPath = path;
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

export default function GoogleAnalytic() {
  const isMounted = useIsMounted();
  const loggedUser = useAtomValue(loggedUserAtom);

  useEffect(() => {
    ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID, { debug: process.env.NEXT_PUBLIC_GOOGLE_ANALYTIC_DEBUG === 'true' });

    if (loggedUser) {
      ReactGA.set({ userId: loggedUser.id });
    }

    watchPath(window.location.pathname);
  }, [isMounted, loggedUser]);

  return '';
}
